<template>
  <div>
    <!-- 依附团队介绍 -->
    <div>
      <div class="content">
        <div class="introduce">
          <h1>成果介绍</h1>
          <div style="height: 20px"></div>
          <h3>国家奖励，发明专利，软件著作</h3>
          <div style="height: 10px"></div>
          <p>
            先后承担国家973、863、科技支持计划、重点研发计划等重要课题20多项。获国家科技进步二等奖3项、国家自然科学二等奖1项、省部级一等奖6余项，拥有已授权发明专利60余项，软件著作权10余项。
          </p>
          <div style="height: 30px"></div>
          <div class="images">
            <img src="@/assets/images/team/gjjl.png" alt="" />
            <img src="@/assets/images/team/fmzl.png" alt="" />
            <img src="@/assets/images/team/rjzz.png" alt="" />
          </div>
        </div>
        <div style="height: 100px"></div>
      </div>

      <div class="course">
        <div class="course-content">
          <div style="height: 60px"></div>
          <h1>发展历程</h1>
          <h3>数字化，网络化，智能化</h3>
          <div style="position: relative">
            <hr class="arrow-hr" style="margin: 40px 0" />
          </div>
          <div class="development-history">
            <div class="stage" v-for="(stage, index) in stage_info" :key="index">
              <div class="point"></div>
              <h3>{{ stage.name }}</h3>
              <span>{{ stage.year }}</span><br /><br />
              <span>{{ stage.desc }}</span><br /><br />
              <span>{{ stage.conclusion }}</span><br /><br />
              <span>{{ stage.achievement }}</span><br /><br />
              <span>{{ stage.awards }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stage_info: [
        {
          name: "第一阶段",
          year: "1986年",
          desc: "在美国从事塑料注射成形模拟研究",
          conclusion: "",
          achievement: "开发了商品化冷却模拟软件",
          awards: "",
        },
        {
          name: "第二阶段",
          year: "1988年",
          desc: "中面模型的模拟",
          conclusion: "（从没有到拥有）",
          achievement: "基于中面的模拟软件",
          awards: "4项省部级科技进步奖",
        },
        {
          name: "第三阶段",
          year: "1996年",
          desc: "表面模型的模拟",
          conclusion: "（从中面到表面）",
          achievement: "基于表面的模拟软件",
          awards: "2002年国家科技进步二等奖",
        },
        {
          name: "第四阶段",
          year: "2002年",
          desc: "实体模型的模拟",
          conclusion: "（从宏观到介观）",
          achievement: "宏介观结合模拟软件",
          awards: "07、10年国家科技自然二等奖",
        },
        {
          name: "第五阶段",
          year: "2007年",
          desc: "成形装备的智能化",
          conclusion: "（从工艺到装备）",
          achievement: "智能型塑料注射机",
          awards: "2019年国家科技进步二等奖",
        },
        {
          name: "第六阶段",
          year: "2019年",
          desc: "从技术研究到应用推广",
          conclusion: "",
          achievement: "2020年成立武汉模鼎科技有限公司",
          awards: "",
        },
      ],
    };
  },
  mounted() {
    this.addListener();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollListener);
  },
  methods: {
    addListener() {
      this.handleScrollListener = function () {
        const history = document.querySelector(".development-history");
        const boxes = history.querySelectorAll(".stage");
        const rect = history.getBoundingClientRect();

        if (rect.top < 830) {
          let delay = 0;
          boxes.forEach((box) => {
            setTimeout(() => {
              box.classList.add("show");
            }, delay);
            delay += 600;
          });
        }
      };
      window.addEventListener("scroll", this.handleScrollListener);
    },
  },
};
</script>

<style lang="less" scoped>
.introduce {
  .images {
    display: flex;
    justify-content: space-between;
  }
}
.course {
  background: url('../../assets/images/home/company/aboutBg.jpg') no-repeat center top;
    .arrow-hr {
    border: none;
    height: 2px;
    background-color: white;
  }
  .course-content {
    width: 1200px;
    height: 770px;
    margin: 0 auto;
  }
  .arrow-hr::after {
    content: "";
    position: absolute;
    top: -9.5px;
    right: 3px;
    width: 20px;
    height: 20px;
    border-top: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }
  .development-history {
    display: flex;
    justify-content: space-between;
    .stage {
      position: relative;
      width: 130px;
      .point {
        position: absolute;
        top: -50px;
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 10px;
      }
      span {
        color: white;
      }
    }
  }
  h3,
  h1 {
    color: white;
    padding-bottom: 20px;
  }
}
.development-history .stage:nth-child(1n) {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.development-history .stage.show {
  opacity: 1;
}
</style>